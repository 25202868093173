import Modal from '../Modal/index.vue'

export default {
    name: 'Home',
    components: {
      'modal': Modal
    },
    data() {
      return {
        isModalVisible: false,
      };
    },
    methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    },
  }
  