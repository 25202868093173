import Vue from 'vue'

import HomeComponent from './Home/index.vue'

export const modal = new Vue();

const vm = new Vue({
  el: '#app',
  components: {
    home: HomeComponent,
  },
  render: h => h('home'),
})
